import { PencilIcon, PlusInCircleIcon } from 'assets/icons';
import { getText } from 'assets/localization/localization';
import { FunctionComponent, useEffect } from 'react';
import SettingsPageLayout from '../../../components/Settings/SettingsPageLayout';
import SettingsSection from '../../../components/Settings/SettingsSection';
import { ContactInfoItemRenderer } from './components/ContactInfoItemRenderer';
import { ContactInfoModal } from './components/ContactInfoModal';
import { DepartmentItemRenderer } from './components/DepartmentItemRenderer';
import { DepartmentModal } from './components/DepartmentModal';
import { usePharmacySettingsState } from './pharmacy-settings-store';

export const PharmacySettings: FunctionComponent = () => {
  const {
    pharmacy,
    departments,
    setShowModalContactInfo,
    setShowModalDepartment,
    setPharmacyId,
    getDepartments,
  } = usePharmacySettingsState();
  useEffect(() => {
    // TODO Currently, this value is hardcoded, but we intend to retrieve it from the login process.
    void setPharmacyId('00253d00-680e-4635-8115-65652c6d14e6');
    void getDepartments();
  }, []);

  return pharmacy ? (
    <SettingsPageLayout title={getText('pharmacy')}>
      <SettingsSection
        title={getText('pharmacy-contact-info')}
        contentHeader={{
          title: getText('pharmacy-general-details'),
          button: {
            hierarchy: 'secondary-gray',
            logger: { id: 'settings-header-button' },
            buttonTitle: getText('edit'),
            icon: PencilIcon,
            onPress: () => setShowModalContactInfo(true),
          },
        }}
        contentData={[pharmacy]}
        contentItem={ContactInfoItemRenderer}
        customTypeModal={ContactInfoModal}
      />
      <SettingsSection
        title={getText('pharmacy-departments')}
        contentHeader={{
          title: getText('pharmacy-names-details'),
          button: {
            hierarchy: 'secondary-gray',
            logger: { id: 'settings-header-button' },
            buttonTitle: getText('new'),
            icon: PlusInCircleIcon,
            onPress: () => setShowModalDepartment(true),
          },
        }}
        contentData={departments}
        contentItem={DepartmentItemRenderer}
        customTypeModal={DepartmentModal}
      />
    </SettingsPageLayout>
  ) : (
    <>Pharmacy N/A</>
  );
};
