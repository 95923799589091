import {
  CreateDepartmentDto,
  DepartmentDto,
  PharmacyDto,
  UpdateDepartmentDto,
  UpdatePharmacyDto,
} from '@digitalpharmacist/pharmacy-service-client-axios';
import create from 'zustand';
import PharmacyService from '../../../api/PharmacyService';
import { useAppStateStore } from '../../../store/app-store';
import { ApiError, AsyncStatus } from '../../../store/types';

export enum CountryCode {
  US = 'US',
  CA = 'CA',
}

export interface PharmacySettingsState {
  showModalContactInfo: boolean;
  showModalDepartment: boolean;
  pharmacy?: PharmacyDto;
  status: AsyncStatus;
  error?: ApiError | unknown;
  editingGeneralInfo: boolean;
  editGeneralInfoId?: string;
  pharmacyId?: string;
  country: CountryCode;
  departments: DepartmentDto[];
  editingDepartmentId?: string;
  editingDepartment?: DepartmentDto;
  setShowModalContactInfo: (showModalContactInfo: boolean) => void;
  setShowModalDepartment: (
    showModalDepartment: boolean,
    editingDepartmentId?: string,
  ) => void;
  setPharmacy: (pharmacy: UpdatePharmacyDto) => Promise<void>;
  updateDepartment: (
    editingDepartment: UpdateDepartmentDto,
    departmentId?: string,
  ) => Promise<void>;
  createDepartment: (department: CreateDepartmentDto) => Promise<void>;
  removeDepartment: (departmentId?: string) => Promise<void>;
  setStatus: (status: AsyncStatus) => void;
  setError: (error: ApiError) => void;
  setCountry: (country: CountryCode) => void;
  setPharmacyId: (pharmacyId: string) => Promise<void>;
  getPharmacy: () => Promise<void>;
  getDepartments: () => Promise<void>;
  getEditingDepartment: () => void;
}

export const usePharmacySettingsState = create<PharmacySettingsState>(
  (set, get) => ({
    showModalContactInfo: false,
    showModalDepartment: false,
    status: 'idle',
    editingGeneralInfo: false,
    error: undefined,
    pharmacyId: useAppStateStore.getState().pharmacyId,
    country: CountryCode.US,
    departments: [],
    setShowModalContactInfo: (showModalContactInfo: boolean) =>
      set({ showModalContactInfo }),
    setShowModalDepartment: (
      showModalDepartment: boolean,
      editingDepartmentId?: string,
    ) => {
      set({ showModalDepartment, editingDepartmentId });
      get().getEditingDepartment();
    },
    setPharmacy: async (pharmacy: UpdatePharmacyDto) => {
      const { pharmacyId } = get();
      if (pharmacyId) {
        await PharmacyService.updatePharmacyRecord(pharmacyId, pharmacy);
        set((prevState) => ({ ...prevState, ...pharmacy }));
      }
    },
    updateDepartment: async (
      editingDepartment: UpdateDepartmentDto,
      departmentId?: string,
    ) => {
      const { pharmacyId, editingDepartmentId, getDepartments } = get();
      departmentId = departmentId || editingDepartmentId;
      if (pharmacyId && departmentId) {
        await PharmacyService.updatePharmacyDepartment(
          pharmacyId,
          departmentId,
          editingDepartment,
        );
        set((prevState) => ({ ...prevState, ...editingDepartment }));
        await getDepartments();
      }
    },
    createDepartment: async (department: CreateDepartmentDto) => {
      const { pharmacyId, getDepartments } = get();
      if (pharmacyId) {
        await PharmacyService.createPharmacyDepartment(pharmacyId, department);
        await getDepartments();
      }
    },
    removeDepartment: async (departmentId?: string) => {
      const { pharmacyId, editingDepartmentId, getDepartments } = get();
      departmentId = departmentId || editingDepartmentId;
      if (pharmacyId && departmentId) {
        await PharmacyService.removePharmacyDepartment(
          pharmacyId,
          departmentId,
        );
        await getDepartments();
        const editingDepartmentId = undefined;
        const editingDepartment = undefined;
        set({ editingDepartmentId, editingDepartment });
      }
    },
    setStatus: (status: AsyncStatus) => set({ status }),
    setError: (error: ApiError) => set({ error }),
    setCountry: (country: CountryCode) => set({ country }),
    setPharmacyId: async (pharmacyId: string) => {
      set({ pharmacyId });
      await get().getPharmacy();
    },
    getPharmacy: async () => {
      const pharmacyId = get().pharmacyId;
      if (!pharmacyId) {
        set({ pharmacy: undefined });
        return;
      }
      const pharmacy: PharmacyDto =
        await PharmacyService.pharmacyGetById(pharmacyId);
      set(() => ({ pharmacy }));
    },
    getDepartments: async () => {
      const pharmacyId = get().pharmacyId;
      if (!pharmacyId) {
        set({ departments: [] });
        return;
      }
      const departments: DepartmentDto[] =
        await PharmacyService.departmentGetByPharmacy(pharmacyId);
      set(() => ({ departments }));
    },
    getEditingDepartment: () => {
      const { editingDepartmentId, departments } = get();
      const editingDepartment = departments.find(
        (dep) => dep.id == editingDepartmentId,
      );
      set({ editingDepartment });
    },
  }),
);
