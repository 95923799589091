import { FunctionComponent, useMemo, Fragment } from 'react';
import { View, TouchableOpacity } from 'react-native';
import { TextInput } from 'react-native-paper';
import EmojiModal from 'react-native-emoji-modal';
import * as DocumentPicker from 'expo-document-picker';
import { MessageTemplateDto } from '@digitalpharmacist/unified-communications-service-client-axios';
import { getText } from 'assets/localization/localization';
import { Text } from 'assets/components/text';
import { makeStyles, useTheme } from 'assets/theme';
import { Modal } from 'assets/components/modal';
import { PaperClipIcon, SmileIcon } from 'assets/icons';
import { getFullName } from '../utils';
import patientService from '../../../api/PatientService';
import { Typeahead } from 'assets/components/typeahead';
import { useAppStateStore } from '../../../store/app-store';
import { useNewChatModalState } from '../new-chat-modal-store/new-chat-modal-store';
import {
  setTemplatesFilter,
  setShowEmojis,
  setErrorFields,
  setInputFieldsData,
  setCursorPosition,
} from '../new-chat-modal-store/new-chat-modal-actions';
import { Attachments } from './Attachments';
import { ErrorsContainer } from './ErrorsContainer';
import { useErrorState } from '../error-store/error-store';
import { ErrorStatus } from '../error-store/error-store';
import { setError } from '../error-store/error-actions';
import {
  MESSAGE_LIMIT,
  ERROR_MODAL_SELECT_FILES,
  ERROR_MODAL_MAX_FILES_COUNT_TEN,
  EXCLUDED_ERRORS_MODAL,
  EMOJI_CHARS_LENGTH,
} from '../data';
import { useOutsideClick } from '../hooks/useOutsideClick';
import { IRecipient } from '../types';

enum Field {
  patient = 'patient',
  subject = 'subject',
  message = 'message',
  attachments = 'attachments',
}

export const NewChatModal: FunctionComponent<NewChatModalProps> = ({
  title = getText('new-chat-message'),
  show,
  recipient,
  templates = [],
  isConversationCreating,
  onCancel,
  onConversationCreate,
}) => {
  const { locationId } = useAppStateStore();
  const {
    templatesFilter,
    showEmojis,
    errorFields,
    inputFieldsData,
    cursorPosition,
  } = useNewChatModalState();
  const { errorObject } = useErrorState();
  const styles = useStyles();
  const theme = useTheme();

  const getTextValues = (): Record<string, any> => {
    const defaultValues: Record<string, any> = {
      [Field.patient]: recipient
        ? [
            {
              text: getFullName(recipient),
              value: recipient.id,
            },
          ]
        : undefined,
      [Field.subject]: getText('your-refill-is-ready'),
      [Field.message]: getText('hi-your-refill-is-ready', {
        firstName: recipient ? ' ' + recipient.first_name : '',
      }),
      [Field.attachments]: [],
    };

    return {
      [Field.patient]:
        inputFieldsData[Field.patient] ?? defaultValues[Field.patient],
      [Field.subject]:
        inputFieldsData[Field.subject] ?? defaultValues[Field.subject],
      [Field.message]:
        inputFieldsData[Field.message] ?? defaultValues[Field.message],
      [Field.attachments]:
        inputFieldsData[Field.attachments] ?? defaultValues[Field.attachments],
    };
  };

  const inputValues = getTextValues();

  const filteredTemplates = useMemo(() => {
    if (!templatesFilter) {
      return templates;
    }

    const regExp = new RegExp(templatesFilter, 'i');
    return templates.filter((template) => regExp.test(template.name));
  }, [templates, templatesFilter]);

  const insertEmoji = (string: string, position: number, emoji: any) => {
    const stringLength = string.length;
    if (
      (!position && position !== 0) ||
      !stringLength ||
      stringLength === position
    ) {
      return string + emoji;
    }

    const firstPart = string.slice(0, position);
    const lastPart = string.slice(-(stringLength - position));
    return firstPart + emoji + lastPart;
  };

  const onFilter = (text: string) => {
    setTemplatesFilter(text);
  };

  const onEmojiSelect = (emoji: any) => {
    const activeField = Object.keys(cursorPosition)[0];
    if (!activeField || activeField === Field.patient) {
      return;
    }
    const cursorPositionIndex = cursorPosition[activeField];
    const newString = inputValues[activeField];

    if (
      !inputFieldsData.message ||
      inputFieldsData.message?.length <= MESSAGE_LIMIT - EMOJI_CHARS_LENGTH
    ) {
      setInputFieldsData({
        ...inputFieldsData,
        [activeField]: insertEmoji(newString, cursorPositionIndex, emoji),
      });
    }
  };

  const onSmileClick = () => {
    setShowEmojis(!showEmojis);
  };

  const outSideClickRef = useOutsideClick(() => {
    setShowEmojis(false);
  });

  const checkFields = (data: any) => {
    const invalidFields: Record<string, string> = {};

    if (!data[Field.patient]) {
      invalidFields[Field.patient] = getText('patient-is-required');
    }
    if (!data[Field.subject]) {
      invalidFields[Field.subject] = getText('subject-is-required');
    }
    if (!data[Field.message]) {
      invalidFields[Field.message] = getText('message-is-required');
    }
    if (data[Field.message] && data[Field.message].length > MESSAGE_LIMIT) {
      invalidFields[Field.message] = getText(
        'message-characters-limit-five-thousand',
      );
    }

    setErrorFields(invalidFields);
    return Object.keys(invalidFields).length;
  };

  const onChatCreate = () => {
    const data = {
      patient:
        inputValues[Field.patient] && inputValues[Field.patient].length
          ? inputValues[Field.patient][0].value
          : undefined,
      subject: inputValues[Field.subject],
      message: inputValues[Field.message],
      attachments: inputValues[Field.attachments],
    };
    const errorsCount = checkFields(data);
    if (errorsCount === 0) {
      onConversationCreate(data);
    }
  };

  const asyncOptions = async (search: string) => {
    const patients = await patientService.getPatientSearch(
      locationId,
      search,
      10,
    );

    const patientList = patients.map((patient) => {
      return {
        text: patient.full_name,
        value: patient.location_patient_record_id,
      };
    });
    return patientList;
  };

  const onSelectTemplate = (template: MessageTemplateDto) => {
    setInputFieldsData({
      ...inputFieldsData,
      [Field.subject]: template.subject,
      [Field.message]: template.body,
    });
  };

  const onSelectAttachments = async () => {
    try {
      const docsResult: DocumentPicker.DocumentResult =
        await DocumentPicker.getDocumentAsync({
          multiple: true,
        });

      if (docsResult.type === 'cancel' || !docsResult.output) {
        setError(
          ERROR_MODAL_SELECT_FILES,
          ErrorStatus.ERROR,
          getText('selecting-files-wrong'),
        );
        return;
      }

      const files = Object.values(docsResult.output);

      if (files.length > 10) {
        setInputFieldsData({
          ...inputFieldsData,
          [Field.attachments]: [],
        });
        setError(
          ERROR_MODAL_MAX_FILES_COUNT_TEN,
          ErrorStatus.ERROR,
          getText('maxim-count-attachments', {
            count: 10,
          }),
        );
        return;
      }

      setInputFieldsData({
        ...inputFieldsData,
        [Field.attachments]: files,
      });
    } catch (error) {
      console.error('Documents picking error: ', error);
    }
  };

  const onRemoveFile = (name: string) => {
    const filteredAttachments = inputValues[Field.attachments].filter(
      (attachment: File) => attachment.name !== name,
    );
    setInputFieldsData({
      ...inputFieldsData,
      [Field.attachments]: filteredAttachments,
    });
  };

  const getMessageTip = () => {
    if (Field.message in errorFields) {
      return (
        <Text style={styles.errorMessage}>{errorFields[Field.message]}</Text>
      );
    } else if (
      inputValues[Field.message] &&
      inputValues[Field.message].length
    ) {
      return (
        <Text style={styles.charactersCounter}>
          {getText('characters-count', {
            count: inputValues[Field.message].length,
          })}
        </Text>
      );
    } else {
      return <></>;
    }
  };

  return (
    <Modal
      size="lg"
      title={title}
      show={show}
      cancelButtonProps={{
        onPress: onCancel,
        text: getText('cancel'),
        logger: { id: 'cancel-button-modal-new-chat' },
        hierarchy: 'tertiary-gray',
      }}
      okButtonProps={{
        onPress: onChatCreate,
        logger: { id: 'ok-button-modal-new-chat' },
        text: getText('send'),
        hierarchy: 'pharmacy-primary',
        disabled: isConversationCreating,
      }}
      isScrollable
    >
      <View
        style={{
          flexDirection: 'row',
          width: '100%',
        }}
      >
        <View
          style={{
            width: 225,
            marginRight: theme.getSpacing(2),
          }}
        >
          <TextInput
            placeholder={getText('find-template')}
            autoComplete="off"
            autoCapitalize="none"
            style={styles.findTemplate}
            underlineColor="transparent"
            outlineColor={theme.palette.white}
            onChangeText={onFilter}
            value={templatesFilter}
          />
          <View style={styles.templatesWrapper}>
            {filteredTemplates.map((template) => (
              <Fragment key={template.id}>
                <TouchableOpacity onPress={() => onSelectTemplate(template)}>
                  <Text style={styles.template}>{template.name}</Text>
                </TouchableOpacity>
              </Fragment>
            ))}
          </View>
        </View>
        <View style={{ flex: 1 }}>
          <ErrorsContainer
            errorObject={errorObject}
            excludedErrors={EXCLUDED_ERRORS_MODAL}
          />
          <View style={[styles.typeahead]}>
            <Typeahead
              disableNoOptionHandler={true}
              emptyValue={undefined}
              placeholder={getText('to')}
              hintMessage={getText('to')}
              asyncOptions={asyncOptions}
              defaultValue={inputValues[Field.patient]}
              onChange={(value: any) => {
                setInputFieldsData({
                  ...inputFieldsData,
                  [Field.patient]: value,
                });
              }}
            />
            {Field.patient in errorFields && (
              <Text style={styles.errorMessage}>
                {errorFields[Field.patient]}
              </Text>
            )}
          </View>
          <View style={{ marginBottom: theme.getSpacing(2) }}>
            <TextInput
              label={getText('subject')}
              autoComplete="off"
              autoCapitalize="none"
              style={styles.input}
              underlineColor="transparent"
              value={inputValues[Field.subject]}
              onChangeText={(text) => {
                setInputFieldsData({
                  ...inputFieldsData,
                  [Field.subject]: text,
                });
              }}
              onSelectionChange={(event) =>
                setCursorPosition({
                  [Field.subject]: event.nativeEvent.selection.start,
                })
              }
            />
            {Field.subject in errorFields && (
              <Text style={styles.errorMessage}>
                {errorFields[Field.subject]}
              </Text>
            )}
          </View>
          <View style={{ marginBottom: theme.getSpacing(1) }}>
            <TextInput
              label={getText('message')}
              autoComplete="off"
              autoCapitalize="none"
              style={[styles.input, { height: 196 }]}
              underlineColor="transparent"
              multiline={true}
              maxLength={MESSAGE_LIMIT}
              value={inputValues[Field.message]}
              onChangeText={(text) => {
                setInputFieldsData({
                  ...inputFieldsData,
                  [Field.message]: text,
                });
                if (Field.message in errorFields) {
                  const errorFieldsCopy = { ...errorFields };
                  delete errorFieldsCopy[Field.message];
                  setErrorFields(errorFieldsCopy);
                }
              }}
              onSelectionChange={(event) =>
                setCursorPosition({
                  [Field.message]: event.nativeEvent.selection.start,
                })
              }
            />
            {getMessageTip()}
          </View>
          {Boolean(inputValues[Field.attachments].length) && (
            <View style={{ marginBottom: theme.getSpacing(1) }}>
              <Attachments
                files={inputValues[Field.attachments]}
                onRemoveFile={onRemoveFile}
              />
            </View>
          )}
          <View style={{ flexDirection: 'row' }}>
            <View style={{ marginRight: 8, position: 'relative' }}>
              <TouchableOpacity onPress={onSmileClick}>
                <SmileIcon size={28} color={theme.palette.gray[500]} />
              </TouchableOpacity>
              {showEmojis && (
                <View style={styles.emojiWrapper} ref={outSideClickRef as any}>
                  <EmojiModal
                    onEmojiSelected={(emoji) => onEmojiSelect(emoji)}
                  />
                </View>
              )}
            </View>
            <TouchableOpacity onPress={onSelectAttachments}>
              <PaperClipIcon size={28} color={theme.palette.gray[500]} />
            </TouchableOpacity>
          </View>
          <Text style={styles.note}>
            {getText('note-inbox-messages-should-be-clinical-in-nature')}
          </Text>
        </View>
      </View>
    </Modal>
  );
};

const useStyles = makeStyles((theme) => ({
  template: {
    ...theme.fonts.regular,
    color: theme.palette.gray[700],
    fontSize: 13,
    marginTop: theme.getSpacing(1),
  },
  templatesWrapper: {
    height: 308,
    overflowY: 'scroll',
  },
  input: {
    height: theme.getSpacing(4) + theme.getSpacing(3),
    backgroundColor: theme.palette.white,
    borderColor: theme.palette.gray[300],
    borderWidth: 1,
    borderRadius: 6,
    fontSize: 18,
  },
  note: {
    ...theme.fonts.regular,
    fontSize: 12,
    color: theme.palette.gray[500],
    marginTop: theme.getSpacing(2),
  },
  emojiWrapper: {
    position: 'absolute',
    bottom: 32,
    zIndex: 10,
    backgroundColor: theme.palette.black,
    padding: theme.getSpacing(1),
    borderRadius: 16,
  },
  errorMessage: {
    color: theme.palette.error[600],
    fontSize: 12,
  },
  charactersCounter: {
    alignSelf: 'flex-start',
    marginTop: theme.getSpacing(1),
    fontSize: 12,
  },
  typeahead: {
    textAlign: 'left',
    marginBottom: theme.getSpacing(2),
    fontSize: 17,
  },
  findTemplate: {
    height: theme.getSpacing(4) + theme.getSpacing(0.5),
    borderWidth: 1,
    borderColor: theme.palette.gray[300],
    backgroundColor: theme.palette.white,
    borderRadius: 6,
  },
}));

export interface CreateConversationData {
  subject: string;
  message: string;
  patient: string;
  attachments?: File[];
}

interface NewChatModalProps {
  title?: string;
  show: boolean;
  recipient?: IRecipient | null | undefined;
  templates: MessageTemplateDto[];
  isConversationCreating: boolean;
  onCancel: () => void;
  onConversationCreate: (data: CreateConversationData) => void;
}
